import * as React from "react";
import { useEffect, useCallback, useRef } from "react";

export const AutoVisible = function({
  active,
  children,
  ...props
}: {
  active: boolean;
  children: React.ReactNode;
}) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (active && ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "auto", block: "nearest" });
    }
  }, [active, ref]);

  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  );
};

const AutoVisibleListItem = function({
  index,
  value,
  label,
  active,
  className,
  onItemSelected,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement> & {
  index: number;
  value: string;
  label: string;
  active: boolean;
  onItemSelected?(index: number);
}) {
  const click = useCallback(
    e => {
      onItemSelected && onItemSelected(index);
      e.preventDefault();
      e.stopPropagation();
    },
    [onItemSelected, value]
  );
  return (
    <AutoVisible active={active}>
      <div
        key={value}
        className={`list-group-item ${active && "active"} ${className}`}
        onClick={click}
        {...props}
      >
        {label}
      </div>
    </AutoVisible>
  );
};

export default React.memo(AutoVisibleListItem);
