import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from './style.module.scss';

type CardProps = {
  id: number;
  name: string;
  href: string;
  title: string;
  image_url: string;
  subtitle: string;
  description: string;
  absolute_url: string;
  btn_label: string;
  labels: string[];
};

// TODO: This is the quick fix for now. We need to come back and futureproof this some more:
function replaceJSONEncodings(str: string) {
  str = str.replace(/&#39;/g, "'");
  return str;
}

function Result({ card }: { card: CardProps; }) {
  return (
    <Card>
      <div className="d-flex flex-column justify-content-end">
        <img src={card.image_url} className="card-img-top"></img>
      </div>
      <Card.Body>
        <Card.Title>{card.title}</Card.Title>
        <Card.Subtitle as="h6">{card.subtitle}</Card.Subtitle>
        <Card.Text>{replaceJSONEncodings(card.description)}</Card.Text>
      </Card.Body>
      <Card.Footer>
        <EmailModal title={card.title} absoluteURL={card.absolute_url} buttonLabel={card.btn_label} />
      </Card.Footer>
    </Card>
  );
}

function EmailModal({ title, absoluteURL, buttonLabel }: { title: string; absoluteURL: string; buttonLabel: string }) {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Button className={'btn card-btn w-100'} onClick={handleShow}>{buttonLabel}</Button>

      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton closeLabel='Close'>
          <Modal.Title><h5>{title}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <div className='embed-responsive embed-responsive-4by3'>
            <IFrame absoluteURL={absoluteURL}></IFrame>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} className='red'>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function IFrame({ absoluteURL }: { absoluteURL: string; }) {
  return (
    <div className={styles.iframeContainer}>
      <iframe className={styles.iframe} src={absoluteURL} />
    </div>
  )
}

type FilterProps = {
  id: string;
  label: string;
  grouping: string;
  checked: boolean;
};

function Filter({ name, filters, onChange }: {name: string; filters: FilterProps[]; onChange?: any;}) {
  return (
    <DropdownButton variant="link" id={name} title={name} className={`d-inline-block mx-3 mb-3 ${styles.filter}`}>
      {filters.map(filter => {
        return (
          <Dropdown.Item key={filter.id} href="#/{filter.id}">
            <Form.Check custom type="checkbox" id={filter.id} className={`m-2 ${styles.customCheckbox}`}>
              <Form.Check.Input checked={filter.checked} onChange={onChange} type="checkbox" className={`custom-control-input' ${styles.customControlInput}`} />
              <Form.Check.Label className={`custom-control-label ${styles.customControlLabel}`}>{filter.label}</Form.Check.Label>
            </Form.Check>
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
}

function AppliedFilters({ filters, handleDismiss }: {filters: FilterProps[], handleDismiss: any}) {
  return (
    <Row>
      {filters.filter(f => f.checked === true).map((f) => {
        return (
          <Col md='auto' key={f.id}>
            <Alert variant='success' onClose={() => {handleDismiss(f.label)}} dismissible>
              {f.label}
            </Alert>
          </Col>
        );
      })}
    </Row>
  );
}

function TuesdayTipsFilter(props: {
  cards: CardProps[];
  allFilters: FilterProps[];
}) {
  const { cards, allFilters } = props;
  const [filters, setFilters] = React.useState<FilterProps[]>(allFilters || [])
  const [visibleCards, setVisibleCards] = React.useState<CardProps[]>(cards || []);

  const handleChange = (e) => {
    const f = allFilters.find(f => f.id === e.target.id);
    if (f !== undefined) {
      f.checked = e.target.checked;
      setFilters([...filters, f]);
    }
  }

  const handleDismiss = (label) => {
    const f = allFilters.find(f => f.label === label);
    if (f !== undefined) {
      f.checked = false;
      setFilters([...filters, f]);
    }
  }

  const filterGroup = (grouping: string) => {
    return allFilters.filter(f => f.grouping === grouping);
  }

  React.useEffect(() => {
    const selectedFilters = allFilters.filter(f => f.checked === true);
    if (selectedFilters.length > 0) {
      const selectedLabels = selectedFilters.map(f => f.id);
      const newCards = cards.filter((card) => {
        return card.labels.some(label => selectedLabels.includes(label))
      });
      setVisibleCards(newCards);
    } else {
      setVisibleCards(cards);
    }
  }, [filters]);

  return (
    <div>
      <Filter name="Topics" filters={filterGroup('topic')} onChange={handleChange} />
      <AppliedFilters filters={allFilters} handleDismiss={handleDismiss} />
      <div className={'global--card'}>
        <div className={'card-deck-grid'}>
          {visibleCards.map((c, idx) => {
            return (
              <Result key={idx} card={c} />
            );
          })}
        </div>
      </div>
    </div>
  );
}

TuesdayTipsFilter.defaultProps = {
  cards: [],
  allFilters: [],
};

export default TuesdayTipsFilter;