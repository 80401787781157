import * as React from "react";
import { useEffect, useState, useMemo, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { FormControlProps } from "react-bootstrap/FormControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useDebounce } from "../../support/useDebounce";
// import { useDebouncedState } from "../../support/useDebouncedState";
import { useDebounce } from "./useDebounce";
import { useDebouncedState } from "./useDebouncedState";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import classnames from "classnames";

export function Searchable(
  props: {
    name?: string;
    icon?: IconDefinition | null;
    iconPosition?: "before" | "after";
    waiting?: boolean;
    value?: string;
    autoFocus?: boolean;
    delayMs?: number;
    selectOnBlur?: boolean;
    blurValue?: string;
    searchBarRenderer({
      setFocused,
      setSearch,
      search,
      searchRegexp,
      highlighted,
      selected,
      focused
    }: {
      setFocused(focused: boolean);
      setSearch(value: string);
      setSelectedIndex(index: number);
      setHighlightedIndex(index: number);
      search: string | null;
      searchRegexp: RegExp;
      highlighted: number | null;
      selected: number | null;
      focused: boolean;
    });
  } & React.HTMLAttributes<HTMLInputElement> &
    FormControlProps
) {
  const {
    icon,
    iconPosition,
    searchBarRenderer,
    placeholder,
    blurValue,
    tabIndex,
    value,
    delayMs,
    waiting,
    selectOnBlur,
    ...rest
  } = props;

  const [search, setSearch] = useState<string>(value || "");

  const [focused, setFocused] = useDebouncedState(false);
  //const focused = true;
  //const setFocused = () => {};

  const [index, setIndex] = useState<{ highlighted: null | number; selected: null | number }>({
    highlighted: null,
    selected: null
  });

  const debouncedSearch = useDebounce(search, delayMs || 0);

  // If the Value prop changes, update the Search state
  useEffect(() => {
    if (value !== undefined) {
      setSearch(value);
    }
  }, [value]);

  // When search changes, we want to update our regex, as well
  const searchRegexp = useMemo(() => {
    return new RegExp(
      (debouncedSearch || "").toString().replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
      "i"
    );
  }, [debouncedSearch]);

  const onKeyDown = useCallback(
    e => {
      let next = { ...index };
      switch (e.keyCode) {
        case 9:
          // tab
          if (selectOnBlur) {
            next.selected = next.highlighted;
          }
          break;
        case 13: // enter
          setFocused(false);
          next.selected = next.highlighted;
          next.highlighted = 0;
          break;
        case 33: // pageup
          setFocused(true);
          if (next.highlighted === null) next.highlighted = 0;
          next.highlighted -= 10;
          break;
        case 34: //pagedown
          setFocused(true);
          if (next.highlighted === null) next.highlighted = 0;
          next.highlighted += 10;
          break;
        case 38: //up
          setFocused(true);
          if (next.highlighted === null) next.highlighted = 0;
          next.highlighted--;
          break;
        case 40: //down
          setFocused(true);
          if (next.highlighted === null) next.highlighted = -1;
          next.highlighted++;
          break;
        case 27: // escape
          setFocused(false);
          next.highlighted = null;
          break;
      }
      if (next.highlighted) {
        next.highlighted = next.highlighted % 65535;
      }

      switch (e.keyCode) {
        case 13:
          if (index.selected !== null) {
            e.stopPropagation();
            e.preventDefault();
          }
          break;
        case 33:
        case 34:
        case 38:
        case 40:
          e.stopPropagation();
          e.preventDefault();
      }
      setIndex(next);
    },
    [index]
  );

  const onChange = useCallback(e => {
    setSearch(e.currentTarget.value);
    setIndex({ highlighted: index.highlighted ? 1 : null, selected: null });
    if (e.currentTarget.value !== "") {
      setFocused(true);
    }
  }, []);

  const onFocus = useCallback(() => setFocused(true), [setFocused]);
  const onBlur = useCallback(() => setFocused(false), [setFocused]);

  const onSetHighlighted = React.useCallback(
    highlighted => setIndex({ highlighted, selected: index.selected }),
    [index]
  );
  const onSetSelected = React.useCallback(
    selected => setIndex({ highlighted: index.highlighted, selected }),
    [index]
  );

  const iconJsx = (
    <div className={classnames({
      "input-group-append": iconPosition === "after",
      "input-group-prepend": iconPosition !== "after"
      })}>
      <span className={`input-group-text`}>
        {waiting && <FontAwesomeIcon icon="spinner" spin />}
        {!waiting && icon && <FontAwesomeIcon icon={icon} />}
        {!waiting && !icon && <FontAwesomeIcon icon={"search"} />}
      </span>
    </div>
  );

  return (
    <div
      tabIndex={1}
      onClick={onFocus}
      onFocus={onFocus}
      onBlur={onBlur}
      style={{ flex: 1, position: "relative", outline: "none" }}
    >
      <div className="input-group">
        {iconPosition !== "after" && iconJsx}
        <Form.Control
          type="search"
          placeholder={placeholder || "Filter..."}
          onChange={onChange}
          autoComplete="off"
          value={blurValue && blurValue !== "" && !focused ? blurValue : search}
          onKeyDown={onKeyDown}
          {...rest}
        />
        {iconPosition === "after" && iconJsx}
      </div>
      {searchBarRenderer({
        search: debouncedSearch,
        searchRegexp,
        highlighted: index.highlighted,
        selected: index.selected,
        setFocused,
        setSearch,
        focused,
        setHighlightedIndex: onSetHighlighted,
        setSelectedIndex: onSetSelected
      })}
    </div>
  );
}
