import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

type CreditCardModalProps = {
  id: number;
  children: React.ReactNode;
};

function CreditCardModal(props: CreditCardModalProps) {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = (data) => {
    console.log("Form was submitted, now the modal can be closed");
    handleClose();
  }
//  const handleSubmit = (e) => {
//    e.preventDefault();
//    console.log("Form was submitted, now the modal can be closed");
//    handleClose();
//  };

  const el = document.getElementById(`cardLink${props.id}`);
  el!.addEventListener("click", handleShow, false);

  return (
    <div>
      <Modal id={`creditCardModal${props.id}`} size='lg' show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title as='h5'>
            Update your payment method
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.children}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" type="submit" form="creditCardForm">Update</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreditCardModal;