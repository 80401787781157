import * as React from "react";
import axios from "axios";
// import RestDataProvider from "../RestDataProvider";
import { useDebouncedState } from "../../lib";
import { Searchable } from "../../lib";
import { AutoVisible } from "../../lib/AutoVisibleListItem";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
// import Button from "react-bootstrap/Button";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import styles from "./style.module.scss";

type ResultProps = {
  highlighted: boolean;
  url: string;
  target: string;
  children: React.ReactNode;
};

type RowProps = {
  row: any;
  highlighted: boolean;
};

function Result({ highlighted, url, target, children }: ResultProps) {
  return (
    <ListGroup.Item className={classnames({ [styles.highlighted]: highlighted })}>
      <a href={url} target={target}>
        <Row>{children}</Row>
      </a>
    </ListGroup.Item>
  );
}

function Account({ row, highlighted }: RowProps) {
  return (
    <Result url={row.href} target={row.target} highlighted={highlighted}>
      <Col>
        {row.name}
        <div className="small">{row.email}</div>
      </Col>
   </Result>
  );
}

function Page({ row, highlighted }: RowProps) {
  return (
    <Result url={row.href} target={row.target} highlighted={highlighted}>
      <Col>
        {row.name}
        <div className="small" dangerouslySetInnerHTML={{__html: row.href_to_s}}></div>
      </Col>
   </Result>
  );
}

interface Record {
  id: number;
  href: string;
  target: string;
}

function ResultGroup({
  data,
  label,
  highlightedIndex,
  offset,
  component
}: {
  data: Record[];
  label: string;
  highlightedIndex: number | null;
  offset: number;
  component: React.ComponentType<RowProps>;
}) {
  let row = offset;
  const El = component;
  return (
    <React.Fragment>
      {data && data.length > 0 && (
        <React.Fragment>
          <ListGroup.Item className="bg-primary text-white">{label}</ListGroup.Item>
          {data.map(r => {
            const active = row++ == highlightedIndex;
            return (
              <AutoVisible active={active} key={r.id}>
                <El row={r} highlighted={active} />
              </AutoVisible>
            );
          })}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

function SearchComponent(props: {
  openRight?: boolean;
  value?: string;
  autoFocus?: boolean;
}) {
  const [data, setData] = React.useState<{
    accounts: Record[];
    pages: Record[];
  }>({ accounts: [], pages: [] });
  const [waiting, setWaiting] = useDebouncedState(false, 100);

  const { autoFocus, value, openRight } = props;

  const searchBarRenderer = ({ search, highlighted, focused, selected }) => {
    // Run this callback when search changes
    React.useEffect(() => {
      if (search !== "") {
        setWaiting(true);
        axios
          .get("/search.json", {
            params: {
              q: search
            }
          })
          .then(res => {
            setWaiting(false);
            setData(res.data);
          });
      }
    }, [search]);

    const accounts = data.accounts || [];
    const pages = data.pages || [];
    const allData = accounts.concat(pages);

    const highlightedIndex =
      highlighted && ((highlighted % allData.length) + allData.length) % allData.length;

    React.useEffect(() => {
      const selectedIndex =
        selected && ((selected % allData.length) + allData.length) % allData.length;
      if (selectedIndex !== null) {
        window.open(
          allData[selectedIndex].href,
          allData[selectedIndex].target
        );
      }
    }, [allData, selected]);

    return (
      focused && (
        <div>
          <ListGroup
            className={classnames({
              [styles.searchResults]: true,
              [styles.onRight]: openRight
            })}
          >
            <ResultGroup
              data={accounts}
              label="Accounts"
              component={Account}
              offset={0}
              highlightedIndex={highlightedIndex}
            />

            <ResultGroup
              data={pages}
              component={Page}
              label="Pages"
              offset={accounts.length}
              highlightedIndex={highlightedIndex}
            />

          </ListGroup>
        </div>
      )
    );


  }

  return (
    <div className={classnames({ [styles.container]: true })} tabIndex={1}>
      <div className="input-group">

        <Searchable
          name="q"
          autoFocus={autoFocus}
          value={value || ""}
          icon={null}
          iconPosition="after"
          waiting={waiting}
          delayMs={150}
          placeholder="Search"
          className={styles.searchBox}
          searchBarRenderer={searchBarRenderer}
        >
        </Searchable>
      </div>
    </div>
  );
}

SearchComponent.defaultProps = {
  openRight: false,
};

export default SearchComponent;
