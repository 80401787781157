import * as React from "react";
import CreditCardForm from './creditCardForm';
import CreditCardModal from './creditCardModal';

type CreditCardProps = {
  id: number;
  name: string;
  number: string;
  expireDate: string;
  cvc: string;
  cardType: string;
  logoClass: string;
  url: string;
  method: string;
  cancelUrl: string;
  useModal: boolean;
};

function CreditCard(props: CreditCardProps) {
  let form = (<CreditCardForm {...props} />);

  // this modal thing was supposed to be for the edit screen but i never got it to work properly.
  // perhaps I'll revisit it one day, so I'll leave it for now.
  if (props.useModal) {
    form = (
      <CreditCardModal {...props}>
        {form}
      </CreditCardModal>
    );
  }

  return(form);
}

export default CreditCard;