import * as React from "react";
//import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import axios, { AxiosRequestConfig, Method } from "axios";
import { useForm, Controller } from 'react-hook-form';
import Cards from 'react-credit-cards';
import * as Luhn from 'luhn';
import classnames from "classnames";

import { formatExpirationDate, formatCreditCardNumber, formatCvc } from './utils';

import 'react-credit-cards/lib/styles.scss';
import styles from './style.module.scss';

type CreditCardFormProps = {
  //id: number;
  name: string;
  number: string;
  expireDate: string;
  cvc: string;
  cardType: string;
  logoClass: string;
  url: string;
  method: string;
  cancelUrl: string;
};

function CreditCardForm(props: CreditCardFormProps) {
  const [focused, setFocused] = React.useState('number');
  const [serverErrors, setServerErrors] = React.useState('');
  const [card, setCard] = React.useState({
    number: props.number,
    expireDate: props.expireDate,
    name: props.name,
    cvc: '',
    cardType: props.cardType,
  });

  const defaultValues = {
    name: card.name,
    number: card.number,
    expireDate: card.expireDate,
    cvc: card.cvc,
    cardType: card.cardType,
  };
  const methods = useForm<CreditCardFormProps>({ defaultValues });
  const {
      control,
      register,
      handleSubmit,
      setValue,
      watch,
      formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    //console.log("submit data", data);
    const config: AxiosRequestConfig = {
      headers: {
        'content-type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || ''
      },
      method: props.method as Method,
      url: props.url,
      data: {payment_method: data},
    }

    // using this to snakify the params for rails (since transformRequest doesn't seem to work)
    axios.interceptors.request.use((config) => {
      const renameParams = (
        ({
          expireDate: card_expire_date,
          cardType: card_type,
          name: card_name,
          number: card_number,
          cvc: card_cvc,
          ...rest
        }) => ({
          card_expire_date,
          card_type,
          card_name,
          card_number,
          card_cvc,
          ...rest
        })
      );
      config.data.payment_method = renameParams(config.data.payment_method);
      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    axios.request(config)
      .then((resp) => {
        window.location.replace(resp.data.redirect);
      })
      .catch((error) => {
        setServerErrors(error.response.data.message);
      });
  }

  const ErrorMessage = () => {
    return (
      <Alert variant="danger"><div dangerouslySetInnerHTML={{__html: serverErrors}}></div></Alert>
    );
  }

  const handleCallback = (data, isValid) => {
    //console.log('handleCallback data', data); // issuer, maxLength
    setValue('cardType', data.issuer);
  };

  const acceptedCards = ['amex', 'discover', 'mastercard', 'visa']; //'dinersclub'

  React.useEffect(() => {
    const subscription = watch((value:CreditCardFormProps, { name, type }) => {
      //console.log(value, name, type);
      setCard(value);
    })
    return () => subscription.unsubscribe();
  }, [watch]);


  return (
    <div id="PaymentForm">
      { serverErrors && <ErrorMessage />}

      <Row className="my-4">
        <Col md={6}>
          <Cards
            name={card.name}
            number={card.number}
            expiry={card.expireDate}
            cvc={card.cvc}
            focused={focused}
            acceptedCards={acceptedCards}
            callback={handleCallback}
          />
        </Col>
      </Row>

      <form id="creditCardForm">
        <input type="hidden" {...register('cardType')} />
        <Row>
          <Col md={6}>
            <div className="form-group">
              <label htmlFor="number">Card Number</label>
              <Controller
                name="number"
                render={({ field }) => (
                  <input
                    type="tel"
                    className="form-control"
                    aria-invalid={errors.number ? "true" : "false"}
                    {...field}
                    onChange={(e) => {
                      field.onChange(setCard({...card, number: e.target.value}));
                      field.onChange(formatCreditCardNumber(e.target.value));
                    }}
                    onFocus={(e) => setFocused(e.target.name)}
                  />
                )}
                control={control}
                rules={{
                  required: 'Card number is required',
                  validate: {
                    validNumber: value => Luhn.validate(value) || 'Invalid card number',
                    validCardType: () => acceptedCards.includes(card.cardType) || 'Must be Amex, Discover, Mastercard, or Visa'
                  }
                }}
              />
              {errors.number && <p className="text-danger" role="alert">{errors.number.message}</p>}
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={4} md={2}>
            <div className="form-group">
              <label htmlFor="expireDate">Expiry</label>
              <Controller
                name="expireDate"
                render={({ field }) => (
                  <input
                    type="tel"
                    className="form-control"
                    aria-invalid={errors.expireDate ? "true" : "false"}
                    {...field}
                    onChange={(e) => {
                      field.onChange(setCard({...card, expireDate: e.target.value}));
                      field.onChange(formatExpirationDate(e.target.value));
                    }}
                    onFocus={(e) => setFocused(e.target.name)}
                  />
                )}
                control={control}
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
                    message: 'Invalid',
                  },
                }}
              />
              {errors.expireDate && <p className="text-danger" role="alert">{errors.expireDate.message}</p>}
            </div>
          </Col>
          <Col xs={4} md={2}>
            <div className="form-group">
              <label htmlFor="cvc">CVC</label>
              <Controller
                name="cvc"
                render={({ field }) => (
                  <input
                    type="tel"
                    className="form-control"
                    aria-invalid={errors.cvc ? "true" : "false"}
                    {...field}
                    onChange={(e) => {
                      field.onChange(setCard({...card, cvc: e.target.value}));
                      field.onChange(formatCvc(e.target.value));
                    }}
                    onFocus={(e) => setFocused(e.target.name)}
                  />
                )}
                control={control}
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^\d{3,4}$/,
                    message: 'Invalid',
                  },
                }}
              />
              {errors.cvc && <p className="text-danger" role="alert">{errors.cvc.message}</p>}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className="form-group">
              <label htmlFor="name">Cardholder name</label>
              <input className="form-control"
                aria-invalid={errors.name ? "true" : "false"}
                {...register('name', {
                  required: 'Cardholder name is required',
                  //onChange: (e) => setCard({...card, name: e.target.value}),
                })}
                onFocus={(e) => setFocused(e.target.name)}
              />
              {errors.name && <p className="text-danger" role="alert">{errors.name.message}</p>}
            </div>
          </Col>
        </Row>

        <Button type="submit" onClick={handleSubmit(onSubmit)}>Save</Button>
        <a className="btn btn-link ml-3" href={props.cancelUrl}>Cancel</a>
      </form>

    </div>
  );

}

export default CreditCardForm;