import { useState, useEffect } from "react";

/*
This hook is used to "smooth" rapidly changing values. You'll use it like:

function MyComponent({value}) {
    const myVal = useDebounce(value, 50)
}

If MyComponent is rapidly re-rendered with varying values for MyComponent, myVal
won't change until it's been unchanged for 50ms, resulting in a final re-render.
This is broadly useful for things like keystroke input and onBlur/onFocus round trips
that happen due to clicks on DOM elements.
*/

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = window.setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      window.clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
