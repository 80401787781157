import React from "react";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive.
}

function PersonalGreeting(props: {
  greetings: [];
  morningGreetings: [];
  afternoonGreetings: [];
  eveningGreetings: [];
  nightGreetings: [];
  name: string;
}) {
  const { greetings, morningGreetings, afternoonGreetings, eveningGreetings, nightGreetings, name } = props;

  let greeting = `Welcome, ${name}`;
  if (getRandomInt(0, 2) == 0) {
    greeting = greetings[getRandomInt(0, greetings.length)];
  } else {
    const hour = new Date().getHours();

    if ([12, 13, 14, 15, 16].includes(hour)) {
      greeting = afternoonGreetings[getRandomInt(0, afternoonGreetings.length)]; // NOON to 4:59 pm = Afternoon
    } else if ([17, 18, 19, 20, 21].includes(hour)) {
      greeting = eveningGreetings[getRandomInt(0, eveningGreetings.length)]; // 5:00 pm to 9:59 pm = Evening
    } else if ([22, 23, 0, 1, 2].includes(hour)) {
      greeting = nightGreetings[getRandomInt(0, nightGreetings.length)]; // 10:00 pm to 2:59 am = Night
    } else {
      greeting = morningGreetings[getRandomInt(0, morningGreetings.length)]; // 3:00 am to 11:59 am = Morning
    }   
  }
  
  greeting = greeting.replace("NAME", name);

  return (
    <div className={'row'}>
      <div className={'col col-auto'}>
        <div className={'greeting mb-3'}>
          {greeting}
        </div>
      </div>
    </div>
  );
}

PersonalGreeting.defaultProps = {
  greetings: [],
  morningGreetings: [],
  afternoonGreetings: [],
  eveningGreetings: [],
  nightGreetings: [],
  name: ""
};

export default PersonalGreeting;