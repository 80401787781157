import * as React from 'react';
import { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";


type Props = {
    assignment_agreements: any;
    csrf_token: string;
  };

function AgreementAssignmentsIndex(props: Props) {
  const columns = [
    "id",
    "agreement_name",
    "psnum",
    "account_name",
    "required",
    "date_signed",
    "longevity",
    "expiration_date",
    "notify",
  ]
  const propsData = Object.values(props.assignment_agreements);

  const data = propsData.map((agreement_assignment) => {
    agreement_assignment = Object.fromEntries(Object.entries(agreement_assignment).filter(([key]) => columns.includes(key)));
    return agreement_assignment
  })



  const getValue = (p) => {
    const field = p.colDef.field;
    return (
      <>
        <div style={{height: "1em"}}></div>
        <div style={{lineHeight: "1.2em"}}>
          <span>{p.data[field]}</span>
        </div>
        <div style={{height: "1em"}}></div>
      </>
    )
  }

  const getDate = (p) => {
    const field = p.colDef.field;
    if (p.data[field]) {
    var date = new Date(p.data[field]).toLocaleString();
    return (
      <>
        <div style={{height: "1em"}}></div>
        <div style={{lineHeight: "1.2em"}}>
          <span>{date}</span>
        </div>
        <div style={{height: "1em"}}></div>
      </>
    )
    }
    else {
    return (
      <>
        <div style={{height: "1em"}}></div>
        <div style={{lineHeight: "1.2em"}}>
          <span>{p.data[field]}</span>
        </div>
        <div style={{height: "1em"}}></div>
      </>
    )
    }
  }

  function onGridSizeChanged(params) {
    const gridWidth = document.querySelector(".ag-body-viewport")!.clientWidth;

    const columnsToShow = [];
    const columnsToHide = [];

    let totalColsWidth = 0;
    const allColumns = params.api.getColumns();
    if (allColumns && allColumns.length > 0) {
      for (let i = 0; i < allColumns.length; i++) {
        const column = allColumns[i];
        totalColsWidth += column.getMinWidth() || 0;
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.getColId());
        } else {
          columnsToShow.push(column.getColId());
        }
      }
    }

    params.api.setColumnsVisible(columnsToShow, true);
    params.api.setColumnsVisible(columnsToHide, false);

    window.setTimeout(() => {
      params.api.sizeColumnsToFit();
    }, 10);
  }

  function onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }


  function DeleteActionRenderer(params) {
    if (params.data.date_signed != null) {
    return (
      <>
        <form class="button_to" method="post" action={`/agreement_assignments/${params.data.id}`}><input type="hidden" name="_method" value="delete" autocomplete="off"></input><button class="btn btn-sm btn-primary" disabled="disabled" type="submit">Delete</button><input type="hidden" name="authenticity_token" value= {props.csrf_token} autocomplete="off"></input></form>
      </>
    );
    }
    else {
    return (
      <>
        <form class="button_to" method="post" action={`/agreement_assignments/${params.data.id}`}><input type="hidden" name="_method" value="delete" autocomplete="off"></input><button class="btn btn-sm btn-primary" type="submit">Delete</button><input type="hidden" name="authenticity_token" value= {props.csrf_token} autocomplete="off"></input></form>
      </>
    );
    }
  }

  function NotifyActionRenderer(params) {
    if (params.data.notify == true) {
    return (
      <>
        <form class="button_to" method="post" action={`/agreement_assignments/${params.data.id}`}><input type="hidden" name="_method" value="patch" autocomplete="off"></input><button class="btn btn-sm btn-danger" type="submit">Turn off</button><input type="hidden" name="authenticity_token" value= {props.csrf_token} autocomplete="off"></input><input type="hidden" name="agreement_assignment[notify]" value="false" autocomplete="off"></input></form>
      </>
    );
    }
    else {
    return (
      <>
        <form class="button_to" method="post" action={`/agreement_assignments/${params.data.id}`}><input type="hidden" name="_method" value="patch" autocomplete="off"></input><button class="btn btn-sm btn-success" type="submit">Turn on</button><input type="hidden" name="authenticity_token" value= {props.csrf_token} autocomplete="off"></input><input type="hidden" name="agreement_assignment[notify]" value="true" autocomplete="off"></input></form>
      </>
    );
    }
  }

  const [quickFilterText, setQuickFilterText] = useState("");
  const [rowData, setRowData] = useState(data);
  const [colDefs, setColDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      cellRenderer: getValue,
      movable: false
    },
    {
      field: "agreement_name",
      headerName: "Agreement Name",
      cellRenderer: getValue,
    },
    {
        field: "psnum",
        headerName: "PSNUM",
        cellRenderer: getValue,
    },
    {
      field: "account_name",
      headerName: "Account Name",
      cellRenderer: getValue,
    },
    {
      field: "required",
      headerName: "Required",
    },
    {
      field: "date_signed",
      headerName: "Date Signed",
      cellRenderer: getDate,
    },
    {
        field: "longevity",
        headerName: "Longevity (In Days)",
        cellRenderer: getValue,
    },
    {
        field: "expiration_date",
        headerName: "Expiration Date",
        cellRenderer: getDate,
    },
    {
        field: "notify",
        headerName: "Notify of Expiration",
    },
    {
        headerName: "Edit Notify",
        field: "edit_notify",
        width: 100,
        cellRenderer: NotifyActionRenderer
    },
    {
      headerName: "Delete",
      field: "delete",
      width: 100,
      cellRenderer: DeleteActionRenderer
    }
  ]);

  const gridOptions = {
    defaultColDef: {
      resizable: false,
      cellStyle: {wordBreak: "normal"},
      wrapText: true,
      autoHeight: true,
      minWidth: 120
    },
    onGridSizeChanged: onGridSizeChanged,
    onFirstDataRendered: onFirstDataRendered,
    columnDefs: colDefs,
    rowData: rowData,
  }

  const onChange = (e) => {
    e.preventDefault();
    setQuickFilterText(e.target.value);
  }

  return (
    <>
      <form>
        <input type="text" placeholder="Search..." value={quickFilterText} onChange={onChange} />
      </form>
      <div className="ag-theme-quartz">
        <AgGridReact
          gridOptions={gridOptions}
          quickFilterText={quickFilterText}
          domLayout='autoHeight'
        />
      </div>
    </>

  );
}

export default AgreementAssignmentsIndex;
