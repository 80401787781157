import { library, dom } from "@fortawesome/fontawesome-svg-core";

// import { faAlignLeft } from "@fortawesome/free-solid-svg-icons/faAlignLeft";
// import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
// import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
// import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons/faBullhorn";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons/faBookOpen";
// import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons/faCalendarCheck";
// import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
// import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight";
// import { faCaretUp } from "@fortawesome/free-solid-svg-icons/faCaretUp";
import { faCcAmex } from "@fortawesome/free-brands-svg-icons/faCcAmex";
import { faCcDiscover } from "@fortawesome/free-brands-svg-icons/faCcDiscover";
import { faCcMastercard } from "@fortawesome/free-brands-svg-icons/faCcMastercard";
import { faCcVisa } from "@fortawesome/free-brands-svg-icons/faCcVisa";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
// import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons/faCloudDownloadAlt";
// import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons/faCheckSquare";
// import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faEnvelope as faEnvelopeO } from "@fortawesome/free-regular-svg-icons/faEnvelope";
// import { faEraser } from "@fortawesome/free-solid-svg-icons/faEraser";
// import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
// import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
// import { faFileExcel } from "@fortawesome/free-solid-svg-icons/faFileExcel";
// import { faFileAlt } from "@fortawesome/free-solid-svg-icons/faFileAlt";
import { faFolder } from "@fortawesome/free-solid-svg-icons/faFolder";
// import { faFolderOpen } from "@fortawesome/free-solid-svg-icons/faFolderOpen";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faHeart } from "@fortawesome/free-solid-svg-icons/faHeart";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
// import { faKey } from "@fortawesome/free-solid-svg-icons/faKey";
// import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons/faMapMarker";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
// import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
// import { faMinusSquare } from "@fortawesome/free-regular-svg-icons/faMinusSquare";
import { faMobile } from "@fortawesome/free-solid-svg-icons/faMobile";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons/faMobileAlt";
import { faPen } from "@fortawesome/free-solid-svg-icons/faPen";
// import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons/faPhoneAlt";
// import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons/faPlusSquare";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons/faQuestionCircle";
// import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
// import { faSquare } from "@fortawesome/free-regular-svg-icons/faSquare";
// import { faStepBackward } from "@fortawesome/free-solid-svg-icons/faStepBackward";
// import { faStepForward } from "@fortawesome/free-solid-svg-icons/faStepForward";
import { faTools } from "@fortawesome/free-solid-svg-icons/faTools";
import { faTh } from "@fortawesome/free-solid-svg-icons/faTh";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
// import { faTools } from "@fortawesome/free-solid-svg-icons/faTools";
// import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faTwitterSquare } from "@fortawesome/free-brands-svg-icons/faTwitterSquare";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faYoutubeSquare } from "@fortawesome/free-brands-svg-icons/faYoutubeSquare";
// import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons/faWindowMinimize";

// Add all icons to the library so you can use it in your page
library.add(faAngleLeft);
library.add(faAngleRight);
// library.add(faAlignLeft);
// library.add(faArrowDown);
// library.add(faArrowLeft);
// library.add(faArrowRight);
// library.add(faArrowUp);
library.add(faBars);
library.add(faBell);
library.add(faBookOpen);
library.add(faBullhorn);
// library.add(faCalendarAlt);
library.add(faCalendarCheck);
// library.add(faCaretDown);
// library.add(faCaretRight);
// library.add(faCaretUp);
// library.add(faCheck);
library.add(faCheckSquare);
library.add(faCcAmex);
library.add(faCcDiscover);
library.add(faCcMastercard);
library.add(faCcVisa);
library.add(faClock);
// library.add(faCloudDownloadAlt);
// library.add(faEdit);
library.add(faEnvelope);
library.add(faEnvelopeO);
// library.add(faEraser);
// library.add(faExclamationTriangle);
library.add(faExternalLinkAlt);
// library.add(faEye);
library.add(faFacebookSquare);
// library.add(faFileExcel);
// library.add(faFileAlt);
library.add(faFolder);
// library.add(faFolderOpen);
library.add(faCog);
library.add(faGlobe);
library.add(faHeart);
library.add(faInfoCircle);
// library.add(faKey);
// library.add(faLink);
library.add(faLock);
library.add(faLinkedin);
library.add(faMapMarker);
library.add(faMapMarkerAlt);
// library.add(faMinus);
// library.add(faMinusSquare);
library.add(faMobile);
library.add(faMobileAlt);
library.add(faPen);
// library.add(faPencilAlt);
library.add(faPhone);
library.add(faPhoneAlt);
// library.add(faPlus);
library.add(faPlusSquare);
library.add(faQuestionCircle);
// library.add(faSave);
library.add(faSearch);
library.add(faShoppingCart);
library.add(faSpinner);
// library.add(faSquare);
// library.add(faStepBackward);
// library.add(faStepForward);
library.add(faTools);
library.add(faTh);
library.add(faTimes);
// library.add(faTools);
// library.add(faTrash);
library.add(faTwitterSquare);
library.add(faUser);
library.add(faYoutubeSquare);
// library.add(faWindowMinimize);

// replacement of i to svg tags is disabled by default, but this reenables it.
dom.watch();
