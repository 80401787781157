import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { useDebounce } from "../../lib";
import axios from "axios";
// import { toast } from "react-toastify";

export function toastErrors(err) {
  const { response } = err;
  if (!response || !response.data) {
    console.log(err);
  }
  /* This uses react-toastify to provide toast popups for messages from any component.
     We could use whatever notification system we want here, and all components that
     consume RestDataProvider would benefit from it.
  */

  /*
  if (response && response.data && response.data.error) {
    toast.error(`"Request failed: ${response.data.error}`);
  } else if (response.data && response.data.errors) {
    toast.error(
      <div>
        <strong>Request failed:</strong>
        <ul>
          {response.data.errors.map((e, i) => (
            <li key={i}>{e}</li>
          ))}
        </ul>
      </div>
    );
  } else {
    toast.error(`${response.status} - ${response.statusText}`);
  }*/
}

/*
   This component's purpose is to accept props like a URL, params, and a debounce delay, to make a network request,
   consume a JSON response from the request, and then pass it to its children. This allows child components to be written
   without needing to know anything about networking - they just receive data as props.

   The <T> declaration declares this as a generic component, which takes a type and uses that type within itself.
   In our case, we'll typically instantiate this with a defaultValue that ends up declaring the datatype returned
   by this instance of RestDataProvider.

   We use the render props pattern here (where children is the render prop) to pass data down the line.

   Typical usage would be something like:

   <RestDataProvider url="/foo/bar.json" params={{search: "whizzbang"}}>
    {({data, refresh}) => {
        return <div>
            Here's my data!
            <button onClick={refresh}>Click me to force a refresh of the data</button>
            <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
    }}
   </RestDataProvider
*/
export default function RestDataProvider<T>({
  url,
  params,
  defaultValue,
  path,
  active,
  delayMs,
  children
}: {
  url: string;
  params?: any;
  defaultValue: T;
  path?: string;
  active?: boolean;
  delayMs?: number;
  children({
    // Our component will accpet a function as a child, which accepts these parameters
    data,
    refresh,
    active,
    running
  }: {
    data: T;
    refresh();
    active: boolean;
    running: boolean;
  });
}) {
  const [data, setData] = useState<T>(defaultValue);
  const [refreshedAt, setRefreshedAt] = useState(0);
  const [running, setRunning] = useState(false);
  const refresh = useCallback(() => {
    setRefreshedAt(new Date().getTime());
  }, []);

  const debouncedSearchParams = useDebounce(params, delayMs || 0);
  useEffect(() => {
    if (active) {
      setRunning(true);
      axios
        .get(url, { params: debouncedSearchParams })
        .then(res => {
          const payload = path ? res.data[path] : res.data;
          setData(payload);
          setRunning(false);
        })
        .catch(toastErrors);
    }
  }, [url, JSON.stringify(debouncedSearchParams), path, refreshedAt]);

  return children({ data, refresh, active: active || false, running });
}
