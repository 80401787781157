// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

import "bootstrap.native/dist/bootstrap-native.esm";

import React from "react";
import ReactDOM from "react-dom";


// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import "../../javascript/src/styles/global/index.scss";
import "../../javascript/src/lib/fontawesome";

import LocalTime from "local-time";
LocalTime.start();

import cash from "cash-dom";
window.$ = cash;

import { mountComponents } from 'react-rails-ujs';

import AddressForm from "@components/AddressForm";
import JobBenchmarks from '@components/JobBenchmarks';
import FeedbackComponent from '@components/FeedbackComponent';
import SearchComponent from '@components/SearchComponent';
import LearnCalculator from '@components/LearnCalculator';
import HomepageDashboard from '@components/HomepageDashboard';
import PersonalGreeting from '@components/PersonalGreeting';
import NotificationCenter from '@components/NotificationCenter';
import TuesdayTipsFilter from '@components/TuesdayTipsFilter';
import GetStartedProgress from '@components/GetStartedProgress';
import CreditCard from '@components/CreditCard';
import PageNavSelect from '@components/PageNavSelect';
import AgreementAssignmentsIndex from '@components/AgreementAssignmentsIndex';
import MultiSelectComponent from "@components/MultiSelectComponent";
import PartnerDirectory from '@components/PartnerDirectory';

mountComponents({
  AddressForm,
  JobBenchmarks,
  FeedbackComponent,
  SearchComponent,
  LearnCalculator,
  HomepageDashboard,
  PersonalGreeting,
  NotificationCenter,
  TuesdayTipsFilter,
  GetStartedProgress,
  CreditCard,
  PageNavSelect,
  AgreementAssignmentsIndex,
  MultiSelectComponent,
  PartnerDirectory
});