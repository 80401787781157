import * as React from 'react';
import { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
// We would use this code if we were using the enterprise version of ag-grid
// import { SetFilterModule } from "ag-grid-enterprise";
// import { ColumnsToolPanelModule } from "ag-grid-enterprise"
// import { ModuleRegistry } from "ag-grid-community";
// ModuleRegistry.registerModules([
//   ColumnsToolPanelModule,
//   SetFilterModule
// ]);


function PartnerDirectory(props) {
  const columns = [
    "id",
    "name",
    "industry_focus",
    "company_niche",
    "certifications",
    "service_area",
    "mentorship",
    "collaboration",
    "contact"
  ]
  const propsData = Object.values(props);
  const data = propsData.map((profile) => {
    profile.contact = {
      email: profile.email,
      phoneNumber: profile.phone_number,
      website: profile.website
    }
    profile = Object.fromEntries(Object.entries(profile).filter(([key]) => columns.includes(key)));
    return profile
  })

  const getName = (p) => {
    return <a href={`/profiles/${p.data.id}`}>{p.data.name}</a>
  }

  const getValue = (p) => {
    const field = p.colDef.field;

    if (p.data[field]) {
      return (
        <>
          {JSON.parse(p.data[field]).map((item, index, arr) => (
            <div key={index} style={{lineHeight: "1.2em"}}>
              {index == 0 && <div style={{height: "1em"}}></div>}
              <span>{item.label}</span>
              <div style={{height: index < arr.length - 1 ? "2em" : "1em"}}></div>
            </div>
          ))}
        </>
      )
    } else {
      return null
    }
  }

  const getString = (p) => {
    const field = p.colDef.field;
    return (
      <>
        <div style={{height: "1em"}}></div>
        <div style={{lineHeight: "1.2em"}}>
          <span>{p.data[field]}</span>
        </div>
        <div style={{height: "1em"}}></div>
      </>
    )
  }

  const getContact = (p) => {
    return (
      <div>
        <a href={`mailto:${p.data.contact.email}`}>{p.data.contact.email}</a><br/>
        {p.data.contact.phoneNumber}<br/>
        <a href={`//${p.data.contact.website}`} target="_blank" rel="noreferrer">{p.data.contact.website}</a>
      </div>
    )
  }

  function onGridSizeChanged(params) {
    const gridWidth = document.querySelector(".ag-body-viewport")!.clientWidth;

    const columnsToShow = [];
    const columnsToHide = [];

    let totalColsWidth = 0;
    const allColumns = params.api.getColumns();
    if (allColumns && allColumns.length > 0) {
      for (let i = 0; i < allColumns.length; i++) {
        const column = allColumns[i];
        totalColsWidth += column.getMinWidth() || 0;
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.getColId());
        } else {
          columnsToShow.push(column.getColId());
        }
      }
    }

    params.api.setColumnsVisible(columnsToShow, true);
    params.api.setColumnsVisible(columnsToHide, false);

    window.setTimeout(() => {
      params.api.sizeColumnsToFit();
    }, 10);
  }

  function onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }


  const [quickFilterText, setQuickFilterText] = useState("");
  const [rowData, setRowData] = useState(data);
  const [colDefs, setColDefs] = useState([
    {
      field: "name",
      headerName: "Name",
      cellRenderer: getName
    },
    {
      field: "industry_focus",
      headerName: "Industry",
      cellRenderer: getValue,
    },
    {
      field: "company_niche",
      headerName: "Company Niche",
      cellRenderer: getValue,
    },
    {
      field: "certifications",
      headerName: "Certifications",
      cellRenderer: getValue,
    },
    {
      field: "mentorship",
      headerName: "Mentorship",
      cellRenderer: getValue
    },
    {
      field: "service_area",
      headerName: "Service Area",
      cellRenderer: getString
    },
    {
      field: "collaboration",
      headerName: "Collaboration"
    },
    {
      field: "contact",
      headerName: "Contact",
      cellRenderer: getContact,
    }
  ]);

  const gridOptions = {
    defaultColDef: {
      resizable: false,
      cellStyle: {wordBreak: "normal"},
      wrapText: true,
      autoHeight: true,
      minWidth: 120
    },
    onGridSizeChanged: onGridSizeChanged,
    onFirstDataRendered: onFirstDataRendered,
    columnDefs: colDefs,
    rowData: rowData,
  }

  const onChange = (e) => {
    e.preventDefault();
    setQuickFilterText(e.target.value);
  }

  return (
    <>
      <form>
        <input type="text" placeholder="Search..." value={quickFilterText} onChange={onChange} />
      </form>
      <div className="ag-theme-quartz">
        <AgGridReact
          gridOptions={gridOptions}
          quickFilterText={quickFilterText}
          domLayout='autoHeight'
        />
      </div>
    </>

  );
}

export default PartnerDirectory;
