import * as React from "react";
import { createRoot } from 'react-dom/client';
import axios from "axios";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// import Dropdown from "react-bootstrap/Dropdown";
// import DropdownButton from 'react-bootstrap/DropdownButton'
// import Form from 'react-bootstrap/Form'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type CardProps = {
  id: number;
  position: number;
  name: string;
  href: string;
  image_url: string;
  title: string;
  subtitle: string;
  description: string;
  btn_href: string;
  target: string;
  completed: boolean;
  preview_btn_text: string;
};

type GetStartedProgressProps = {
  cards: CardProps[];
  overallProgress: number;
  progressIncrement: number;
  allCompletionMessages: string[];
  csrfToken: string;
};

function CompleteButtonOrText(props: {
  card: CardProps;
  updateProgress(): void;
  csrfToken: string;
}) {
  const { card, updateProgress, csrfToken } = props;

  const completedText = <div className={'completed-text mt-4'}>Completed</div>;

  const handleMarkComplete = (e) => {
    axios.post("get_started", {position: e.currentTarget.value, authenticity_token: csrfToken})
    .then(function (response) {
      const pid = response["data"]["positionUpdated"];
      if (pid === null) {
        console.log("update failed");
      } else {
        const id = `completionButtonHolder${pid}`;
        const container = document.getElementById(id);
        const root = createRoot(container!);
        root.render(completedText);
        updateProgress();
      }
    });
  }

  if (card.completed) {
    return completedText;
  }
  return (
    <Button className={'mt-2 mark-complete'}
            value={card.position}
            onClick={handleMarkComplete}>Mark Complete</Button>
  )
}


function StepCard(props: {
  card: CardProps;
  updateProgress(): void;
  csrfToken: string;
}) {
  const { card, updateProgress, csrfToken } = props;

  return (
    <Card>
      <div className="d-flex flex-column justify-content-end">
        <a href={card.btn_href}>
          <Card.Img variant="top" src={card.image_url} />
        </a>
      </div>
      <Card.Body>
        <Card.Title as="h6">{card.title}</Card.Title>
        <Card.Subtitle as="h6">{card.subtitle}</Card.Subtitle>
        <Card.Text>
          <div dangerouslySetInnerHTML={{__html: card.description}}></div>
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <a className={'btn card-btn w-100'}
           href={card.btn_href}
           target={card.target}>{card.preview_btn_text}</a>
        <div id={'completionButtonHolder' + card.position}>
          <CompleteButtonOrText card={card} updateProgress={updateProgress} csrfToken={csrfToken} />
        </div>
      </Card.Footer>
    </Card>
  );
}

function isEmpty(str) {
  return (!str || str.length === 0);
}

function OverallSection(props: {
  progress: number;
  progressIncrement: number;
  allMessages: string[];
}) {
  const { progress, progressIncrement, allMessages } = props;
  const message = allMessages[Math.round(progress / progressIncrement) - 1];
  let messageAlert;

  if (!isEmpty(message)) {
    messageAlert = <div className={'alert alert-info'}>{message}</div>;
  }

  return (
    <div>
      {progress < 99 &&
        <div className={'progress mb-4'}>
          <div id="overallProgressBar"
              className={'progress-bar progress-bar-striped bg-info progress-bar-animated'}
              style={{width: `${progress}%`}} />
        </div>
      }
      {messageAlert}
    </div>
  );
}

function GetStartedProgress(props: GetStartedProgressProps) {
  const { cards, overallProgress, progressIncrement, allCompletionMessages, csrfToken } = props;
  const [progress, setProgress] = React.useState(overallProgress);

  const updateProgress = React.useCallback(
    () => {
      const newProgress = progress + progressIncrement;
      setProgress(newProgress);
    },
    [progress]
  );

  return (
    <div>
      <OverallSection progress={progress} progressIncrement={progressIncrement} allMessages={allCompletionMessages} />
      <div className={'global--card'}>
        <div className={'card-deck-grid'}>
          {cards.map((c, idx) => {
            return (
              <StepCard key={idx} card={c} updateProgress={updateProgress} csrfToken={csrfToken} />
            );
          })}
        </div>
      </div>
    </div>
  );
}

GetStartedProgress.defaultProps = {
  cards: [],
  overallProgress: 5,
  progressIncrement: 20,
  allCompletionMessages: [],
  csrfToken: ''
};

export default GetStartedProgress;