import * as React from "react";
import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import styles from './style.module.scss';

function SubscriptionDropdown({
  label,
  text, 
  onClickHandler,
  options,
  optionValues
}: { 
  label: string, 
  text: string, 
  onClickHandler(e: any): void,
  options: string[],
  optionValues: number[]
}) {
  return (
    <div className={`row mb-0 pb-0`}>
      <div className="col-md-6 d-flex justify-content-end pt-2">{label}</div>
      <div className="col-md-6 d-flex">
        <DropdownButton variant="link" id="0" title={text} className={`${styles.dropdownToggle} d-inline-block mb-3`}>
          <Dropdown.Item key={'stp1'} id={`${optionValues[0]}`} onClick={onClickHandler}>{options[0]}</Dropdown.Item>
          <Dropdown.Item key={'stp2'} id={`${optionValues[1]}`} onClick={onClickHandler}>{options[1]}</Dropdown.Item>
          <Dropdown.Item key={'stp3'} id={`${optionValues[2]}`} onClick={onClickHandler}>{options[2]}</Dropdown.Item>
        </DropdownButton>
      </div>
    </div>
  );
}

function TextComponent({ 
  label, 
  subLabel,
  infoText,
  infoKey,
  placeholder,
  onChangeHandler
}: { 
  label: string, 
  subLabel: string,
  infoText: string,
  infoKey: string,
  placeholder: string,
  onChangeHandler(e: any): void 
}) {
  return (
    <Form.Group className={`row mt-2 pb-0`}>
      <div className="col-md-6 d-flex pt-0 justify-content-end">
        <Form.Label className={`${styles.fieldLabel}`}>
          {label}
          { infoText.trim() != "" &&
            <OverlayTrigger
              key="{infoKey}"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${infoKey}`}>
                  {infoText}
                </Tooltip>
              }
            >
              <Button className={`${styles.infoBtn} btn ml-2 p-0 btn-xs btn-dark`} tabIndex={-1}>
                <i className="fa fa-info-circle fa-sm ml-1 mr-1"></i>
              </Button>
            </OverlayTrigger>
          }
          { subLabel.trim() != "" &&
            <div className={styles.fieldSubLabel}>{subLabel}</div>
          }
        </Form.Label>
      </div>
      <div className="col-md-6 d-flex">
        <Form.Control type="text" placeholder={placeholder} required className={`${styles.formControl} w-50`} onChange={onChangeHandler}/>
        <Form.Control.Feedback type="invalid" className="mt-2 ml-2">
          This is a required field.
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  );
}

function formatInput( input: string ) {
  let stripped = input.replace(/[^\w.]+/g, "");
  let num = parseFloat(parseFloat(stripped).toFixed(2));
  return num;
}

function formatNumber(num) {
  return Math.round(num).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function LearnCalculator() {
  const [subscriptionTimePeriod, setSubscriptionTimePeriod] = useState(0);
  const [subscriptionTimePeriodText, setSubscriptionTimePeriodText] = useState("Select Option");

  const [subscriptionCost, setSubscriptionCost] = useState(0);
  const [subscriptionCostText, setSubscriptionCostText] = useState("Select Option");

  const [ssClients, setSSClients] = useState(0); // Single Science
  const [msClients, setMSClients] = useState(0); // Multi-Science

  const [chargePerSSCourse, setchargePerSSCourse] = useState(0);
  const [chargePerMSCourse, setchargePerMSCourse] = useState(0);

  const [percFullCertification, setPercFullCertification] = useState(0);
  const [chargePerExam, setChargePerExam] = useState(0);

  const [revenueText, setRevenueText] = useState("");
  const [profitText, setProfitText] = useState("");
  const [roiText, setRoiText] = useState("");

  const processTimePeriod = React.useCallback(
    (e) => {
      setSubscriptionTimePeriod(e.target.id);
      setSubscriptionTimePeriodText(e.target.text);
    }, []
  );

  const processCost = React.useCallback(
    (e) => {
      setSubscriptionCost(e.target.id);
      setSubscriptionCostText(e.target.text);
    }, []
  );

  const handleSingleScienceClientsChange = React.useCallback(
    (e) => {
      setSSClients(formatInput(e.target.value));
    }, []
  );

  const handleMultipleScienceClientsChange = React.useCallback(
    (e) => {
      setMSClients(formatInput(e.target.value));
    }, []
  );

  const handleSingleScienceChargeChange = React.useCallback(
    (e) => {
      setchargePerSSCourse(formatInput(e.target.value));
    }, []
  );

  const handleMultiScienceChargeChange = React.useCallback(
    (e) => {
      setchargePerMSCourse(formatInput(e.target.value));
    }, []
  );

  const processPercFullCertification = React.useCallback(
    (e) => {
      setPercFullCertification(formatInput(e.target.value));
    }, []
  );

  const processChargePerExam = React.useCallback(
    (e) => {
      setChargePerExam(formatInput(e.target.value));
    }, []
  );

  const [validated, setValidated] = useState(false);

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      let numClientsInterested = (percFullCertification / 100) * (ssClients + msClients);
      let amtGeneratedFromJustLMS = ((ssClients * chargePerSSCourse) + (msClients * chargePerMSCourse)) * subscriptionTimePeriod;
      let amtGeneratedFromUpselling = (numClientsInterested * chargePerExam) * subscriptionTimePeriod;
      let costOfCertifications = numClientsInterested * 195;
      let costOfCertOverSubPeriod = costOfCertifications * subscriptionTimePeriod;
      let costs = Number(subscriptionCost) + costOfCertOverSubPeriod;

      let revenue = amtGeneratedFromJustLMS + amtGeneratedFromUpselling;
      let profit = revenue - costs;
      let roi = 100 * (profit / (costOfCertOverSubPeriod + Number(subscriptionCost)));
      roi = Math.round(roi * 10 ) / 10;

      setRevenueText("$" + formatNumber(revenue));
      setProfitText("$" + formatNumber(profit));
      setRoiText(roi.toFixed(1) + "%");
    }
  }

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <SubscriptionDropdown 
          label="Subscription time period" 
          text={subscriptionTimePeriodText} 
          onClickHandler={processTimePeriod} 
          options={["12 Months", "6 Months", "3 Months"]} 
          optionValues={[12, 6, 3]}
        />
        <SubscriptionDropdown 
          label="Learn Subscription cost" 
          text={subscriptionCostText} 
          onClickHandler={processCost} 
          options={["12 Months: $1,500", "6 Months: $1,050", "3 Months: $750"]} 
          optionValues={[1500, 1050, 750]}
        />
        <TextComponent
          label="# of clients interested in single science courses per month" 
          subLabel=""
          placeholder=""
          infoText=""
          infoKey=""
          onChangeHandler={handleSingleScienceClientsChange} 
        />
        <TextComponent 
          label="# of clients interested in multi-science courses per month" 
          subLabel=""
          placeholder=""
          infoText=""
          infoKey=""
          onChangeHandler={handleMultipleScienceClientsChange} 
        />
        <TextComponent
          label="The amount you'll charge per single science course" 
          subLabel="(Suggested retail price of $495 for a single science course)"
          placeholder=""
          infoText="We suggest charging between four and ten times the cost of Learn."
          infoKey="1"
          onChangeHandler={handleSingleScienceChargeChange} 
        />
        <TextComponent
          label="The amount you'll charge for multiple science courses" 
          subLabel="(Suggested retail price of $899 for a multi-science course)"
          placeholder=""
          infoText="We suggest charging between four and ten times the cost of Learn."
          infoKey="2"
          onChangeHandler={handleMultiScienceChargeChange} 
        />
        <TextComponent
          label="% of interested clients that will complete full certification:" 
          subLabel=""
          placeholder=""
          infoText=""
          infoKey=""
          onChangeHandler={processPercFullCertification} 
        />
        <TextComponent
          label="The amount you'll charge per certification exam" 
          subLabel="(Suggested retail price of $600 per certification exam)"
          placeholder=""
          infoText=""
          infoKey=""
          onChangeHandler={processChargePerExam} 
        />
        <div className={`row`}>
          <div className="col-md-12 d-flex justify-content-center pt-2 pb-3">
            <Button type="submit" className="border-0 btn red">Calculate</Button>
          </div>
        </div>
        <OutputRow label="Total revenue generated from Learn in 1 year:" value={revenueText} />
        <OutputRow label="Profit:" value={profitText} />
        <OutputRow label="ROI:" value={roiText} />
      </Form>
    </div>
  );
}

function OutputRow({ 
  label, 
  value
}: { 
  label: string, 
  value: string
}) {
  return(
    <div className={`row mt-2 mb-2`}>
      <div className={`${styles.outputLabel} col-md-6 d-flex pt-0 justify-content-end`}>
        {label}
      </div>
      <div className={`${styles.outputValue} col-md-6 d-flex pt-0`}>
        {value}
      </div>
    </div>
  );
}

export default LearnCalculator;