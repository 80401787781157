import * as React from "react";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
// import styles from './style.module.scss';
import { useState } from "react";
import RestDataProvider from "../RestDataProvider";
import Spinner from "react-bootstrap/Spinner";

type Link = {
  account: string;
  link_name: string;
  count: number;
};

function LinkRow({ link, index }: { link: Link, index: number }) {
  return (
    <tr className="small">
      <td scope="row" key="a{index}" className="font-weight-bold">{link.account}</td>
      <td key="l{index}">{link.link_name}</td>
      <td key="c{index}">{link.count}</td>
    </tr>
  );
}

function updatedTime() {
  const d = new Date();
 
  // Convert to msec. Add local time zone offset. Get UTC time in msec.
  const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
  const nd = new Date(utc + (3600000*-8));

  return "Updated " + nd.toLocaleString() + " MST";

}

function dayOfYear() {
  const now = new Date();
  const start = new Date(now.getFullYear(), 0, 0);
  const epochMilliseconds = now.getTime() - start.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const day = Math.floor(epochMilliseconds / oneDay);
  return day;
}

function HomepageDashboard() {
  const [days, setDays] = useState(7);
  const [dropdownText, setDropdownText] = useState("7 days");
  const [loading, setLoading] = useState(true);
  const [updateTime, setUpdateTime] = useState(updatedTime);

  const handleClick = (e) => {
    setLoading(true);
    setDays(e.target.id);
    setDropdownText(e.target.text);
    setUpdateTime(updatedTime);
  }

  return (
    <div>
      <Row className="header justify-content-around justify-content-xl-start">
        <Col xs="auto">Latest activity over the past</Col>
        <Col xs="auto">
          <DropdownButton variant="outline-secondary" id="4s" title={` ${dropdownText} `}>
            <Dropdown.Item key={'id1'} id="1" onClick={handleClick}>24 hours</Dropdown.Item>
            <Dropdown.Item key={'id2'} id="7" onClick={handleClick}>7 days</Dropdown.Item>
            <Dropdown.Item key={'id3'} id="30" onClick={handleClick}>30 days</Dropdown.Item>
            <Dropdown.Item key={'id4'} id="60" onClick={handleClick}>60 days</Dropdown.Item>
            <Dropdown.Item key={'id5'} id="365" onClick={handleClick}>365 days</Dropdown.Item>
            <Dropdown.Item key={'id6'} id={` ${dayOfYear()} `} onClick={handleClick}>Year to Date</Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>
      <table className="table table-striped table-sm">
        <thead className="text-white bg-primary font-weight-bold small">
          <tr>
            <th key="th1">Account</th>
            <th key="th2">Link Name</th>
            <th key="th4">Count</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
              <tr>
                <td colSpan={4}>
                  <Spinner animation="grow" variant="info" />
                  <Spinner animation="grow" variant="info" />
                  <Spinner animation="grow" variant="info" />
                </td>
              </tr>
          )}
          <RestDataProvider url={"/dashboards.json"} params={{ day_range: days }} active={true} defaultValue={[] as Link[]}>
            {({ data, refresh, running }) =>
              {
                setLoading(running);
                if (!data || (data.length == 0 && !running)) {
                  return(<tr><td colSpan={4}>no results found</td></tr>);
                } else {
                  return data.map((link, index) => {
                    return (<LinkRow key={`link${index}`} link={link} index={index} />);
                  })
                }
              }
            }
          </RestDataProvider>
        </tbody>
      </table>
      {!loading && (
        <Row className="border-top">
          <Col className="small text-md-right">{updateTime}</Col>
        </Row>
      )}
    </div>
  );
}

export default HomepageDashboard;