import * as React from "react";
// import * as ReactDOM from "react-dom";
import axios from "axios";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Alert from "react-bootstrap/Alert";
// import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Toast";
// import Dropdown from "react-bootstrap/Dropdown";
// import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form';
import styles from './style.module.scss';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type FeedbackComponentProps = {
  uri: string;
  pageTitle: string;
  body: string;
  csrfToken: string;
};
FeedbackComponent.defaultProps = {
  uri: window.location,
};

function FeedbackComponent(props: FeedbackComponentProps) {
  const { uri, pageTitle, body, csrfToken } = props;
  const [show, setShow] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = event => {
    event.preventDefault();

    const form = event.currentTarget;
    const formData = new FormData(form);
    formData.append('authenticity_token', csrfToken);
    const config = {     
      headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post('/feedbacks', formData, config);
    handleClose();
    setShowNotification(true);
  };

  return (
    <div>
      <Button className={styles.feedback} disabled={show} variant="success" size="lg" onClick={handleShow}>
        Feedback
      </Button>

      {showNotification && (
        <Toast className={styles.notification}
               show={showNotification}
               onClose={() => setShowNotification(false)}
               delay={3000}
               autohide>
          <Toast.Header>
            <i className="fas fa-heart text-red mr-1"></i>
            <strong className="mr-auto">Thank you</strong>
            <small>Comment sent</small>
          </Toast.Header>
          <Toast.Body>Your feedback is helpful, thanks again!</Toast.Body>
        </Toast>
      )}

      {show && (
        <Modal show={show} onHide={handleClose} centered aria-labelledby='modal-feedback'>
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id='modal-feedback'>Help us improve</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className='lead'>
                Your suggestions and feedback are helpful to us.
                If you found something which can be improved, please let us know.
              </p>
              <p className='small text-muted'>
                Page to report:&nbsp;
                <a href={uri}>{pageTitle}</a>
              </p>
              <Form.Group controlId="formFeedback">
                <Form.Label>Please let us know how we can improve:</Form.Label>
                <Form.Control name="feedback[body]" as="textarea" rows={5} />
                <Form.Control name="feedback[uri]" type="hidden" value={uri} />
                <Form.Control name="feedback[page_title]" type="hidden" value={pageTitle} />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">Send Feedback</Button>
              <Button variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </div>
  );
}

export default FeedbackComponent;