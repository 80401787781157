import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

type Props = {
  options: [];
  value: string;
  name: string;
  id: string;
  disabled: boolean;
};

const MultiSelectComponent = (props: Props) => {

  const [selected, setSelected] = useState(JSON.parse(props.value));

  const allOptions = props.options.concat(JSON.parse(props.value))

  const result = (props.options.concat(JSON.parse(props.value))).filter((value:[{label, value}], index, self) =>
  index === self.findIndex((t:[{label, value}]) => (
    t["label"] === value["label"] && t["value"] === value["value"]
  ))
)

  return (
    <div>
      <input value={JSON.stringify(selected)} type="hidden" name= {props.name} id={props.id}></input>
      <MultiSelect
        disabled = {props.disabled}
        options={result}
        value={selected}
        onChange={setSelected}
        labelledBy="Select"
        isCreatable = {true}
      />
    </div>
  );
};

export default MultiSelectComponent;