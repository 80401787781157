import * as React from "react";
import { useState } from "react";

type JobBenchmark = {
  id: number;
  label: string;
};

function JobBenchmark({ jobBenchmark }: { jobBenchmark: JobBenchmark }) {
  return (
    <li>
      <a href={`/job_benchmark/${jobBenchmark.id}`}>{ jobBenchmark.label }</a>
    </li>
  );
}

function JobBenchmarks({ jobBenchmarks }: { jobBenchmarks: JobBenchmark[] }) {
  const [visibleJobs, setVisibleJobs] = React.useState<JobBenchmark[]>(jobBenchmarks || []);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    return false;
  }

  const filterJobs = (e) => {
    const searchTerm = e.target.value;
    let visibleJobs;
    if (searchTerm.length > 0) {
      visibleJobs = jobBenchmarks.filter(jb => jb.label.toLowerCase().includes(searchTerm.toLowerCase()));
    } else {
      visibleJobs = jobBenchmarks;
    }
    return setVisibleJobs(visibleJobs);
  }

  return (
    <div className="container">
      <h4>Job Benchmark Search</h4>
      <p>
        TTI's Job Benchmarking Library was designed for VAAs to use as a reference point during the
        job benchmarking process.
      </p>

      <div className="input-group">
        <input 
          className="form-control"
          type="text"
          placeholder="Enter your job title keyword"
          onChange={e => filterJobs(e)}
        >
        </input>
        <div className="input-group-append">
          <div className="input-group-text">
            <i className="fa fa-search" onClick={e => e.preventDefault()}>
            </i>
          </div>
        </div>
      </div>

      <ul className="mt-3">
        {visibleJobs.map((jb, idx) => {
          return (
            <JobBenchmark key={idx} jobBenchmark={jb} />
          );
        })}
      </ul>

    </div>
  );
} 

JobBenchmarks.defaultProps = {
  jobBenchmarks: []
};

export default JobBenchmarks;
