import { useState, useEffect } from "react";

/*
This hook just combines useDebounce with useState to give you a state variable and setter
that is backed by a delayed debouncer. This is very useful for things like keyboard input. Consider:

function MyComponent() {
    const [focused, setFocused] = useState(false);

    const onFocus = useEffect(() => setFocused(true), [setFocused])
    const onBlur = useEffect(() => setFocused(false), [setFocused])

    return <div onFocus={onFocus} onBlur={onBlur} tabIndex={1}>
      <input type="text" placeholder="Type here" />
      {focused && <button>Click me!</button>}
    </div>;
}

If we didn't debounce the state setter, then focusing the input then focusing then attempting
to click the button would make the button disappear just-in-time before being clicked, as the DOM
will briefly blur the focus before re-establishing it. By debouncing the focus, we smooth over this
behavior so we can capture focus within our component.
*/

export function useDebouncedState(initial, delay = 25) {
  const [value, setValue] = useState(initial);
  const [debouncedValue, setDebouncedValue] = useState(initial);

  useEffect(() => {
    const handler = window.setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => window.clearTimeout(handler);
  }, [value, delay]);

  return [debouncedValue, setValue];
}
