import * as React from "react";
import { createRoot } from 'react-dom/client';
import axios from "axios";
import { useState, useEffect } from "react";
import styles from './style.module.scss';

function NotificationCenter(props: {
  whatsHappeningIndicator: number;
  productUpdatesIndicator: number;
  tuesdayTipsIndicator: number;
  monthlyMinutesIndicator: number;
  csrfToken: string;
}) {
  const {
    whatsHappeningIndicator,
    productUpdatesIndicator,
    tuesdayTipsIndicator,
    monthlyMinutesIndicator,
    csrfToken,
  } = props;
  const [whIndicator, setWHIndicator] = useState(whatsHappeningIndicator);
  const [puIndicator, setPUIndicator] = useState(productUpdatesIndicator);
  const [ttIndicator, setTTIndicator] = useState(tuesdayTipsIndicator);
  const [mmIndicator, setMMIndicator] = useState(monthlyMinutesIndicator);

  function clearCounter(index: number) {
    switch (index) {
      case 1: {
        setPUIndicator(0);
        break;
      }
      case 2: {
        setTTIndicator(0);
        break;
      }
      case 3: {
        setMMIndicator(0);
        break;
      }
    }
    setWHIndicator(0);
  }

  function Indicator({ counter }: { counter: number }) {
    if (counter <= 0) {
      return null;
    }
    return (
      <span className={`badge badge-danger ${styles.indicator}`}>
        {counter}
      </span>
    );
  }

  const handleClick = (e) => {
    const slugs = [
      "news-feed",
      "technical_updates",
      "tuesday-tips",
      "monthly-minutes",
    ];
    const slug = e.target.id;
    axios
      .post("dashboards", { slug: slug, authenticity_token: csrfToken })
      .then(function (response) {
        const pid = response["data"]["slugUpdated"];
        if (pid === null) {
          console.log("update failed");
        } else {
          clearCounter(slugs.indexOf(slug));
        }
      });
  };

  useEffect(() => {
    const tabContainer = document.getElementById("notification-center");
    if (tabContainer != null) {
      const tabs = tabContainer.getElementsByTagName("A");
      Array.from(tabs).forEach((tab) =>
        tab.addEventListener("show.bs.tab", handleClick)
      );
    }
  }, []);

  useEffect(() => {
    // ReactDOM.render(
    //   <Indicator counter={whIndicator} />,
    //   document.getElementById("whatsHappeningIndicator")
    // );
    var container = document.getElementById("whatsHappeningIndicator");
    var root = createRoot(container!);
    root.render(<Indicator counter={whIndicator} />);

    // ReactDOM.render(
    //   <Indicator counter={puIndicator} />,
    //   document.getElementById("productUpdatesIndicator")
    // );
    var container = document.getElementById("productUpdatesIndicator");
    var root = createRoot(container!);
    root.render(<Indicator counter={puIndicator} />);

    // ReactDOM.render(
    //   <Indicator counter={ttIndicator} />,
    //   document.getElementById("tuesdayTipsIndicator")
    // );
    var container = document.getElementById("tuesdayTipsIndicator");
    var root = createRoot(container!);
    root.render(<Indicator counter={ttIndicator} />);

    //ReactDOM.render(
    //  <Indicator counter={mmIndicator} />,
    //  document.getElementById("monthlyMinutesIndicator")
    //);
  });

  return null;
}

NotificationCenter.defaultProps = {
  whatsHappeningIndicator: 0,
  productUpdatesIndicator: 0,
  tuesdayTipsIndicator: 0,
  monthlyMinutesIndicator: 0,
  csrfToken: "",
};

export default NotificationCenter;
