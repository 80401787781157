import * as React from "react";
import Dropdown from "react-bootstrap/Dropdown";

import styles from './style.module.scss';

type PageProps = {
  id: number;
  label: string;
  href: string;
};

function PageNavSelect(props: {
  id: string;
  pages: PageProps[];
}) {
  const { id, pages } = props;

  const currentPage = pages.find(p => p.href === window.location.pathname);
  let label = 'Overview';
  if (currentPage !== undefined) {
    label = currentPage.label;
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="link" id={id} className={`d-inline-block mx-3 mb-3 ${styles.dropdownToggle}`}>
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {pages.map(page => {
          return (
            <Dropdown.Item key={page.id} href={page.href} className={styles.dropdownItem}>
              {page.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

PageNavSelect.defaultProps = {
  pages: [],
};

export default PageNavSelect;